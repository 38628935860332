<template>
  <v-row class="upsell-page">
    <!-- Config layout -->
    <MenuApps :menu="menu" :defaultActiveMenu="defaultActiveMenu" />
    <v-col class="upsell-page-container">
      <div class="upsell-page-content">
        <router-view />
      </div>
    </v-col>
  </v-row>
</template>

<script>
import MenuApps from '../components/MenuApps';
import event from '@/plugins/event-bus';

export default {
  components: {
    MenuApps,
  },
  mounted() {
    event.$emit('getStepStatus', message => {
      this.getStep();
    });
  },
  data() {
    return {
      menu: [
        {
          title: 'UpSell',
          icon: 'fas fa-home',
          name: 'website.apps.upsell.list',
        },
        {
          title: 'Cross-sell',
          icon: 'fas fa-tags',
          children: [
            {
              title: 'Quantily discounts',
              name: 'website.apps.quantity.list',
            },
            {
              title: 'Gifts',
              name: 'website.apps.gift.list',
            },
            {
              title: 'Bundles',
              name: 'website.apps.bundle.list',
            },
            {
              title: 'Product widgets',
              name: 'website.apps.widgets.list',
            },
          ],
        },
      ],
      defaultActiveMenu: 'website.apps.upsell.list',
    };
  },
};
</script>

<style lang="scss">
.upsell-page {
  height: 100% !important;
  .upsell-page-container {
    @include flex(center, flex-center);
    overflow-y: auto;
    padding-left: 260px;
    & .upsell-page-content {
      max-width: 1024px;
      min-width: 700px;
      margin-top: 40px;
    }
  }
}
</style>
